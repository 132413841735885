<template>
  <div class="visualization-wrapper"
    ref="visHeight"
    :class="{'visualization': isV2visualization}">
    <iframe v-if="isV2visualization"
      id="racersVisualization"
      :src="iframeSrc"
      frameborder="0"
      scrolling="no">
    </iframe>
    <Transition v-if="!isV2visualization" name="fade">
      <div class="game-logo" v-if="showLogo">
        <div class="logo"><i :class="icon"/></div>
      </div>
    </Transition>
    <video-js v-if="!isV2visualization"
              :parsedSources="parsedSources"
              @hideLogo="hideLogo">
    </video-js>
  </div>
</template>

<script>
import {
  each,
  kebabCase,
  isNil,
  cloneDeep,
} from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import stream from '@nsftx/games-sdk-js/src/utility/stream';
import VideoJs from './VideoJS';

export default {
  name: 'Visualization',
  props: {
    icon: {
      type: String,
      default: 'icon icon-greyhound-races-v',
    },
  },
  components: {
    VideoJs,
  },
  data() {
    return {
      parsedSources: [],
      showLogo: true,
      visualizationHeight: '432px',
      isGameStateTransferred: false,
    };
  },
  computed: {
    ...mapGetters([
      'config',
      'streamConfig',
      'activeProductName',
      'visualizationUrl',
      'gameState',
      'getSetStateMessage',
    ]),
    ...mapGetters({
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    getCompanyUuid() {
      return stream.getStreamUuid(this.config.environment, this.config.tenantId)
        || this.config.tenantId;
    },
    isMobile() {
      return window.innerWidth < 640;
    },
    isStagingEnv() {
      return this.config.environment === 'staging';
    },
    isV2visualization() {
      if (this.config.ui.config.racersV2visualization
        && this.config.ui.config.racersV2visualization === true) {
        return true;
      }
      return false;
    },
    iframeSrc() {
      const device = this.isMobile ? 'mobile' : 'desktop';
      const path = `
          ?layout=${device}
          &environment=${this.config.environment}
          &channel=${this.config.applicationName}
          &platform=${this.config.platformName}
          &platformDataCenter=${this.getDc()}
          &productId=${this.config.productId}
          &tenantId=${this.config.tenantId}
          &locale=${this.config.locale}
          &productName=${(this.config.productAlias).toLowerCase()}
          &debugWindow=false
          &messaging=postmessage`
        .replace(/[\n\r]+ */g, '');
      return `${this.visualizationUrl}/${path}`;
    },
  },
  methods: {
    ...mapActions([
      'getStreamToken',
    ]),
    ...mapActions({
      setMobileVisualizationHeight: 'betslip/setMobileVisualizationHeight',
    }),
    init() {
      const pageUrl = this.getParentUrl();
      const plugin = this.streamConfig;
      plugin.data.stream = this.getStreamName(plugin.data.resolution.high);
      if (plugin && plugin.origin) {
        this.setStreamUrl(plugin, pageUrl);
      }
    },
    getParentUrl() {
      return window.location.origin;
    },
    getStreamName(resolution) {
      return `${kebabCase(this.config.productAlias)}_${resolution}`;
    },
    setStreamUrl(plugin, pageUrl) {
      this.getStreamToken({ plugin, pageUrl }).then(() => {
        const { token } = this.streamConfig;
        if (token) {
          each(plugin.data.sources, (sourceItem) => {
            const source = sourceItem;
            let path = '';
            if (source.protocol === 'rtmp') {
              path = `/${this.getCompanyUuid}?token=${token}/${plugin.data.stream}`;
            } else {
              path = `/${this.getCompanyUuid}/${plugin.data.stream}${source.extension}?token=${token}&page=${pageUrl}`;
            }

            source.src = source.protocol + plugin.origin.url + path;
            this.parsedSources.push(source);
          });
        }
      });
    },
    onFullScreenChange() {
      setTimeout(() => {
        this.setMobileVisualizationHeight(this.$refs.visHeight.clientHeight);
      }, 1000);
    },
    getDc() {
      if (this.config.ui.config.platformDataCenter) {
        return this.config.ui.config.platformDataCenter;
      }
      return 'en';
    },
    hideLogo() {
      setTimeout(() => {
        this.showLogo = false;
      }, 1000);
    },
    getVisualizationHeight(height) {
      if (height >= 359 && height <= 479) {
        this.visualizationHeight = '270px';
      } else if (height >= 480 && height <= 639) {
        this.visualizationHeight = '320px';
      } else if (height >= 640 && height <= 767) {
        this.visualizationHeight = '360px';
      } else if (height >= 768 && height <= 895) {
        this.visualizationHeight = '432px';
      } else if (height >= 896 && height <= 1023) {
        this.visualizationHeight = '504px';
      } else if (height >= 1024 && height <= 1279) {
        this.visualizationHeight = '768px';
      } else if (height >= 1280) {
        this.visualizationHeight = '720px';
      }
    },
    observeWidth() {
      const resizeObserver = new ResizeObserver(() => {
        this.getVisualizationHeight(this.$refs.visHeight.clientWidth);
      });
      resizeObserver.observe(this.$refs.visHeight);
    },
    transferState(event) {
      if (event.data === 'VisualizationReady' && !this.isGameStateTransferred) {
        const interval = setInterval(() => {
          if (!isNil(this.gameState)) {
            clearInterval(interval);
            const transferData = cloneDeep(this.getSetStateMessage);
            document.getElementById('racersVisualization').contentWindow.postMessage(transferData, '*');
            this.isGameStateTransferred = true;
          }
        }, 0);
      }
    },
  },
  created() {
    if (!this.isV2visualization) {
      this.init();
    }
  },
  mounted() {
    this.observeWidth();
    setTimeout(() => {
      this.setMobileVisualizationHeight(this.$refs.visHeight.clientHeight);
    }, 1000);
    window.addEventListener('message', (event) => {
      this.transferState(event);
    });
    document.addEventListener('fullscreenchange', this.onFullScreenChange, false);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenChange, false);
    document.addEventListener('mozfullscreenchange', this.onFullScreenChange, false);
  },
};
</script>

<style lang="scss" scoped>
.visualization-wrapper {
  position: relative;
  .fade-enter-active, .fade-leave-active {
  transition: opacity 1s
  }
  .fade-enter, .fade-leave-to {
    opacity: 0
  }
  .game-logo {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1D2124;
    z-index: 1;
    .logo {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      .icon {
        color: #fff;
        font-size: 55px;
        padding-right: 10px;
        line-height: 30px;
        opacity: .7;
      }
    }
  }
  iframe {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
  }
}
@media (max-width: 479px) {
  .visualization-wrapper {
    &.visualization {
      padding-top: 75%;
    }
  }
}
@media (min-width: 480px) and (max-width: 640px) {
  .visualization-wrapper {
    &.visualization {
      padding-top: 66.66%;
    }
  }
}
@media (min-width: 641px) {
  .visualization-wrapper {
    &.visualization {
      padding-top: 56.25%;
    }
  }
}
</style>
